import { Button, Card, Form, message, Typography, Row, Col, Switch } from 'antd';
import React, { FC, FormEvent } from 'react';
import { useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { FormComponentProps } from 'antd/lib/form';
import { useUpdateOrganizationMutation } from '../../../hooks/mutations/useUpdateOrganization';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  organizationId: string;
}

interface ConsumerSettingsFormData {
  enablePatientCancellation: boolean;
  enablePatientRescheduling: boolean;
}

const AppointmentWorkflowCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateOrganizationSettings] = useUpdateOrganizationMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const organization = data?.getOrganization;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(async (err, values: ConsumerSettingsFormData) => {
      try {
        await updateOrganizationSettings({
          variables: {
            organizationId: organizationId,
            updateOrganizationInput: {
              enablePatientCancellation: values.enablePatientCancellation,
              enablePatientRescheduling: values.enablePatientRescheduling,
            },
          },
        });
        message.success('Your changes have been saved.');
      } catch (err) {
        message.error('An error occurred while saving your changes');
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card
        title="Appointment Settings"
        extra={
          <BlockRestrictedUsers>
            <Button htmlType="submit" type="primary">
              Save Appointment Settings
            </Button>
          </BlockRestrictedUsers>
        }
      >
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Enable Patient Self-Cancellation">
              {form.getFieldDecorator('enablePatientCancellation')(
                <Switch defaultChecked={organization?.enablePatientCancellation} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Enable/disable the ability for the patient to cancel the appointment on their own
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Enable Patient Self-Rescheduling">
              {form.getFieldDecorator('enablePatientRescheduling')(
                <Switch defaultChecked={organization?.enablePatientRescheduling} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Enable/disable the ability for the patient to reschedule the appointment on their own
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export const AppointmentWorkflowCard = Form.create<Props>({ name: 'Workflow Settings' })(
  AppointmentWorkflowCardComponent
);
