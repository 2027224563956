import { Button, DatePicker, Divider, Select } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import * as moment from 'moment';
import queryString from 'query-string';
import React, { FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { TypeStatusEnum } from '../../pages/ReferralsContainer';

const Styles = styled.div`
  .wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .button-wrapper {
    display: flex;
  }

  .search-btn {
    margin-left: 0.5rem;
  }

  .reset-btn {
  }
`;

interface HistoryParamsInterface {
  locationId?: string;
  organizationId?: string;
  type?: TypeStatusEnum;
  startDate?: string;
  endDate?: string;
  dateRangeText?: string;
}

export const DATE_RANGE = {
  ALL_TIME: 'ALL_TIME',
  TODAY: 'TODAY',
  THIS_WEEK: 'THIS WEEK',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_90_DAYS: 'LAST_90_DAYS',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  LAST_12_MONTHS: 'LAST_12_MONTHS',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
};

export const DATE_RANGE_DEFAULT = DATE_RANGE.LAST_90_DAYS;

const { RangePicker } = DatePicker;
const { Option } = Select;
const DATE_FORMAT = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY', 'MM-DD-YY', 'MM/DD/YY', 'MMDDYY'];

// TODO default values should be fetch from query params
const ReferralsDateRangeComponent: FC<RouteComponentProps & { default?: string }> = (props): JSX.Element => {
  const { history } = props;
  const queryParams: HistoryParamsInterface = queryString.parse(history.location.search);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<moment.Moment>(
    queryParams.startDate ? moment.default(queryParams.startDate) : moment.default().subtract(30, 'days')
  );
  const [endDate, setEndDate] = useState<moment.Moment>(
    queryParams.endDate ? moment.default(queryParams.endDate) : moment.default()
  );
  const [selectValue, setSelectValue] = useState<string | undefined>(
    queryParams.dateRangeText ? queryParams.dateRangeText : props.default || DATE_RANGE_DEFAULT
  );

  // used to check whether the dropdown should close or allow user to click
  let lock = 0;

  // updating the URL and adding URL param for dates will force the referrals table query to refetch
  const updateURL = (theStartDate: moment.Moment, theEndDate: moment.Moment, dateRangeText: string): void => {
    const queryParams: HistoryParamsInterface = queryString.parse(history.location.search);

    history.push(
      history.location.pathname +
        '?' +
        queryString.stringify({
          ...queryParams,
          startDate: theStartDate.toISOString(),
          endDate: theEndDate.toISOString(),
          dateRangeText,
        })
    );
  };

  const onDropdownVisibleChange = (open: boolean): void => {
    if (lock) return;

    setIsOpen(open);
  };

  // Needed so dropdown doesn't close when you are trying to set custom dates and clicking
  const handleLock = (): void => {
    clearTimeout(lock);
    lock = window.setTimeout(() => {
      lock = 0;
    }, 100);
  };

  const handleSearch = (): void => {
    const dateRangeText = `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`;
    setIsOpen(false);
    setSelectValue(dateRangeText);
    updateURL(startDate, endDate, dateRangeText);
  };

  const handleRangeOnChange = (dates: RangePickerValue): void => {
    if (dates && dates[0] && dates[1]) {
      const start = moment.default(dates[0]);
      const end = moment.default(dates[1]);
      const modifiedStart = start.startOf('day');
      const modifiedEnd = end.endOf('day');
      setStartDate(modifiedStart);
      setEndDate(modifiedEnd);
    } else if (dates && dates[0]) {
      const start = dates[0];
      const modifiedStart = start.startOf('day');
      setStartDate(modifiedStart);
      setEndDate(moment.default());
    } else if (dates && dates[1]) {
      const end = dates[1];
      const modifiedEnd = end.endOf('day');
      setStartDate(moment.default().startOf('year'));
      setEndDate(modifiedEnd);
    }
  };

  const handleSelected = (selected: string): void => {
    let fromDate = moment.default();
    let toDate = moment.default();

    switch (selected) {
      case DATE_RANGE.ALL_TIME:
        toDate = moment.default().add(2, 'years');
        fromDate = moment.default().subtract(2, 'years');
        break;
      case DATE_RANGE.TODAY:
        toDate = moment.default().endOf('day');
        fromDate = moment.default().startOf('day');
        break;
      case DATE_RANGE.THIS_WEEK:
        toDate = moment.default().endOf('week');
        fromDate = moment.default().startOf('week');
        break;
      case DATE_RANGE.LAST_30_DAYS:
        toDate = moment.default().endOf('day');
        fromDate = moment
          .default()
          .subtract(30, 'day')
          .startOf('day');
        break;
      case DATE_RANGE.LAST_90_DAYS:
        toDate = moment.default().endOf('day');
        fromDate = moment
          .default()
          .subtract(90, 'day')
          .startOf('day');
        break;
      case DATE_RANGE.THIS_MONTH:
        toDate = moment.default().endOf('month');
        fromDate = moment.default().startOf('month');
        break;
      case DATE_RANGE.LAST_MONTH:
        toDate = moment
          .default()
          .subtract(1, 'month')
          .endOf('month');
        fromDate = moment
          .default()
          .subtract(1, 'month')
          .startOf('month');
        break;
      case DATE_RANGE.LAST_12_MONTHS:
        toDate = moment.default().endOf('day');
        fromDate = moment
          .default()
          .subtract(1, 'year')
          .startOf('day');
        break;
      case DATE_RANGE.THIS_YEAR:
        toDate = moment.default().endOf('year');
        fromDate = moment.default().startOf('year');
        break;
      case DATE_RANGE.LAST_YEAR:
        toDate = moment
          .default()
          .subtract(1, 'year')
          .endOf('year');
        fromDate = moment
          .default()
          .subtract(1, 'year')
          .startOf('year');
        break;
      default:
        break;
    }

    setStartDate(fromDate);
    setEndDate(toDate);
    setSelectValue(selected);
    updateURL(fromDate, toDate, selected);
  };

  return (
    <Styles>
      <div className="wrapper">
        <Select<string>
          value={selectValue}
          onSelect={selected => handleSelected(selected.toString())}
          onDropdownVisibleChange={onDropdownVisibleChange}
          open={isOpen}
          defaultValue={props.default || DATE_RANGE_DEFAULT}
          style={{ width: '250px' }}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ padding: '8px', cursor: 'pointer' }} onMouseDown={handleLock} onMouseUp={handleLock}>
                <RangePicker separator="-" format={DATE_FORMAT} onChange={handleRangeOnChange} />
                <Button type="primary" block style={{ marginTop: '8px' }} onClick={handleSearch}>
                  Search
                </Button>
              </div>
            </div>
          )}
        >
          <Option value={DATE_RANGE.ALL_TIME}>All Time</Option>
          <Option value={DATE_RANGE.TODAY}>Today</Option>
          <Option value={DATE_RANGE.THIS_WEEK}>This Week</Option>
          <Option value={DATE_RANGE.LAST_30_DAYS}>Last 30 Days</Option>
          <Option value={DATE_RANGE.LAST_90_DAYS}>Last 90 Days</Option>
          <Option value={DATE_RANGE.THIS_MONTH}>This Month</Option>
          <Option value={DATE_RANGE.LAST_MONTH}>Last Month</Option>
          <Option value={DATE_RANGE.LAST_12_MONTHS}>Last 12 Months</Option>
          <Option value={DATE_RANGE.THIS_YEAR}>This Year</Option>
          <Option value={DATE_RANGE.LAST_YEAR}>Last Year</Option>
        </Select>
      </div>
    </Styles>
  );
};

export const ReferralsDateRange = withRouter(ReferralsDateRangeComponent);
