import { Button, Col, Dropdown, Icon, Menu, message, Row } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { gql } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Questionnaire,
  QuestionnaireAnswerSet,
  useAddTaskMutation,
  useListWorkflowQueuesQuery,
  useListExternalWorkflowQueuesQuery,
} from '../../../../../generated/graphql';
import { FeatureGate, FeatureFlag } from '../../../../../helpers';
import { AppContext } from '../../../../core/contexts/AppContext';
import { QueueTitle } from '../../../../workflowQueues/components/QueueTitle';
import { SearchWizardContainerContext } from '../../../pages/SearchWizardContainer';
import { QuestionnaireModalForm } from '../../Referral/QuestionnaireModal';

interface Props {
  accessCenterProfileId?: string;
  handleSendToProvider: (arg0: null) => void;
  referral: {
    id: string;
    tasks: {
      id: string;
      status: string;
      viewerIsOwner: boolean;
      startedAt?: string;
      finishedAt?: string;
      insertedAt: string;
      workflowQueue: {
        id: string;
        name: string;
        color: string | null;
      };
    }[];
  };
}

const getProfile = gql`
  query GetProfile($profileId: ID!) {
    getProfile(id: $profileId) {
      id
      displayName
      type
      phone
      profileImgUrl
      professionalStatement
      boardCertifications
      educationTraining
      npiNumber
      specialty
      isIntegrated
      isSendToProvider
      isSendToPatient
      languages {
        code
        name
      }
      location {
        id
        name
        address1
        address2
        city
        state
        postalCode
        distanceFromPatient
        distanceFromProvider
        latitude
        longitude
      }
      organization {
        id
        name
        referralSettings {
          isDiagnosisRequired
          isNoteRequired
        }
      }
    }
  }
`;

export const SearchHeaderExternalRefCard: FC<Props> = ({
  handleSendToProvider,
  accessCenterProfileId,
  referral,
}): JSX.Element | null => {
  const { loading, error, data } = useQuery(getProfile, {
    variables: { profileId: accessCenterProfileId },
    skip: accessCenterProfileId === undefined || accessCenterProfileId === null,
  });

  const { currentOrganization } = useContext(AppContext);
  const { taskId } = useContext(SearchWizardContainerContext);
  const history = useHistory();
  const [addTask] = useAddTaskMutation();

  const [questionModal, setQuestionModal] = useState<
    [Questionnaire | undefined, (arg0: string, arg1: QuestionnaireAnswerSet[] | []) => void]
  >([undefined, _v => {}]);

  const workflowQueues = useListWorkflowQueuesQuery({
    variables: { organizationId: currentOrganization?.id || '' },
  });

  const externalWorkflowQueues = useListExternalWorkflowQueuesQuery({
    variables: { organizationId: currentOrganization?.id || '' },
  });

  if (loading || error) return null;

  const existingQueues = referral.tasks.map(v => {
    return v.workflowQueue.id;
  });

  const manualQueues = workflowQueues?.data?.listWorkflowQueues?.filter(q => {
    return q.manualTrigger && q.triggerType === 'referral';
  });

  const manualExternalQueues = externalWorkflowQueues?.data?.listExternalWorkflowQueues?.filter(q => {
    return q.manualTrigger && q.triggerType === 'referral';
  });

  const addTaskCallback = async (
    referralId: string,
    workflowQueueId: string,
    queueName: string,
    notes?: string,
    questionnaireAnswerSets?: QuestionnaireAnswerSet[]
  ): Promise<void> => {
    await addTask({
      variables: {
        referralId: referralId,
        workflowQueueId: workflowQueueId,
        notes: notes,
        taskId: taskId,
        questionnaireAnswerSets: questionnaireAnswerSets,
      },
    });
    message.success(`Referral successfully sent to ${queueName}`);
    history.replace(`/referrals/${referral.id}`);
  };

  async function handleMenuClick(e: ClickParam): Promise<void> {
    const [prefix, id] = e.key.split(':');
    let queue: any;
    if (prefix === 'wq') {
      queue = manualQueues?.find(q => q.id === id);
    } else if (prefix === 'ewq') {
      queue = manualExternalQueues?.find(q => q.id === id);
    }

    if (queue) {
      if (queue.questionnaires && queue?.questionnaires[0] && queue?.questionnaires[0].items.length !== 0) {
        setQuestionModal([
          queue.questionnaires[0],
          (notes: string, questionnaireAnswers: QuestionnaireAnswerSet[] | []) => {
            addTaskCallback(referral.id, id, queue.name, notes, questionnaireAnswers);
          },
        ]);
      } else {
        addTaskCallback(referral.id, id, queue.name);
      }
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {manualQueues?.map(
        q =>
          q.manualTrigger &&
          !existingQueues.includes(q.id) && (
            <Menu.Item key={`wq:${q.id}`}>
              <Icon type="arrow-right" />
              <QueueTitle name={`Send to ${q.name}`} color={q.color} />
            </Menu.Item>
          )
      )}
      {manualQueues?.map(
        q =>
          q.manualTrigger &&
          existingQueues.includes(q.id) && (
            <Menu.Item key="none">
              <Icon type="arrow-right" />
              <QueueTitle name={<i>{`Already sent to ${q.name}`}</i>} color={q.color} />
            </Menu.Item>
          )
      )}
      {manualExternalQueues?.map(
        q =>
          q.manualTrigger && (
            <Menu.Item key={`ewq:${q.id}`}>
              <Icon type="arrow-right" />
              <QueueTitle name={<i>{`Send to ${q.name}`}</i>} color={q.color} />
            </Menu.Item>
          )
      )}
    </Menu>
  );

  const [modalQuestionnaire, modalCallback] = questionModal;

  return (
    <Styles>
      <FeatureGate feature={FeatureFlag.HasUnlistedProvider}>
        <Row>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Button icon="arrow-right" onClick={() => handleSendToProvider(null)}>
              Send to Unlisted Provider
            </Button>
          </Col>
        </Row>
      </FeatureGate>
      <FeatureGate feature={FeatureFlag.HasSendToAccessCenter}>
        <Row>
          <Col span={24} style={{ textAlign: 'left', paddingTop: 10 }}>
            <Button
              icon="arrow-right"
              onClick={() => {
                if (accessCenterProfileId === null && data === undefined) {
                  console.debug(
                    'You must add a profile id in the organization super admin for the access center profile'
                  );
                } else {
                  handleSendToProvider(data.getProfile);
                }
              }}
            >
              Send to Access Center
            </Button>
          </Col>
        </Row>
      </FeatureGate>
      <FeatureGate feature={FeatureFlag.ReferralSearchWorkflowQueue}>
        <Row>
          <Col span={24} style={{ textAlign: 'left', paddingTop: 10 }}>
            <QuestionnaireModalForm
              onCancel={() => setQuestionModal([undefined, () => {}])}
              onOk={modalCallback}
              questionnaire={modalQuestionnaire}
              visible={modalQuestionnaire !== undefined}
            />
            <Dropdown trigger={['click']} overlay={menu} placement="bottomRight">
              <Button>
                Send to Workflow...
                <Icon type="down" />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </FeatureGate>
    </Styles>
  );
};

const Styles = styled.div`
  .externalRefCard {
    border: 0px;
  }
`;
