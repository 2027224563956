import { Avatar, Button, Col, Comment, Form, Input, List, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { Mutation, Query } from '@apollo/client/react/components';
import { CreateMessage } from './CreateChatMutation';
import { ReferralData } from './GetReferralQuery';
import { ListMessagesQueryData, ListMessagesVariables, QUERY } from './ListMessagesQuery';

export const ChatCard: FC<{ referral: ReferralData }> = ({ referral }): JSX.Element => {
  const [message, setMessage] = useState('');
  return (
    <Query<ListMessagesQueryData, ListMessagesVariables> query={QUERY} variables={{ referralId: referral.id }}>
      {({ loading, data, refetch }) => {
        if (loading || !data || !data.listMessages) {
          return null;
        }
        return (
          <>
            <Row>
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    {/* <div style={{ height: 200, overflow: 'auto' }}> */}
                    <div>
                      <List
                        className="comment-list"
                        itemLayout="horizontal"
                        dataSource={data.listMessages}
                        renderItem={item => (
                          <li>
                            <Comment
                              author={item.user.name}
                              avatar={<Avatar style={{ backgroundColor: '#87d068' }} icon="user" />}
                              content={item.body}
                              datetime={
                                <Tooltip
                                  title={moment
                                    .utc(item.insertedAt)
                                    .local()
                                    .format('MMMM Do YYYY, h:mm:ssA')}
                                >
                                  {moment
                                    .utc(item.insertedAt)
                                    .local()
                                    .fromNow()}
                                </Tooltip>
                              }
                            />
                          </li>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>{/* <AttachmentsCard referral={referral} /> */}</Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ marginTop: 10 }}>
                  <Mutation<{}, { createMessageInput: { referralId: string; body: string } }>
                    mutation={CreateMessage}
                    update={() => {
                      refetch();
                      setMessage('');
                    }}
                  >
                    {createMessage => (
                      <Row>
                        <Col span={18}>
                          <Form.Item>
                            <Input.TextArea
                              rows={1}
                              placeholder="Type your message here..."
                              value={message}
                              onChange={e => setMessage(e.target.value)}
                              onPressEnter={() => {
                                createMessage({
                                  variables: { createMessageInput: { referralId: referral.id, body: message } },
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6} push={1}>
                          <Form.Item>
                            <Button
                              type="primary"
                              icon="enter"
                              onClick={() => {
                                createMessage({
                                  variables: { createMessageInput: { referralId: referral.id, body: message } },
                                });
                                setMessage('');
                              }}
                            >
                              Send
                            </Button>
                            {/* <Button icon="paper-clip" /> */}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Mutation>
                </div>
              </Col>
            </Row>
          </>
        );
      }}
    </Query>
  );
};
