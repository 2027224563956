import { Avatar, Button, Card, Col, Divider, Icon, Row, Tag, Typography } from 'antd';
import React, { FC, Fragment, useState } from 'react';
import { useAppContext } from '../../../../hooks';
import { FeatureFlag, FeatureGate, formatEmail, formatPhone, formatTitleCase } from '../../../../helpers';
import * as ptFormat from '../../../../helpers/patients.helper';
import { CoverageData, PatientData } from './GetReferralQuery';
import styled from 'styled-components';
import AddEditPatientForm from './AddEditPatientForm';
import { useGetOrganizationQuery } from '../../../../hooks/queries/useGetOrganizationQuery';

const { Text, Title } = Typography;

interface PatientCardProps {
  patient: PatientData;
  coverage: CoverageData;
  isPreauthorized: boolean;
}

const Styles = styled.div`
  .ant-card-meta-title {
    white-space: normal;
  }

  .sub-text {
    font-size: 14px;
  }
`;

const PatientCard: FC<PatientCardProps> = ({ patient, coverage, isPreauthorized }): JSX.Element => {
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [patientState, setPatientState] = useState(patient);

  const { currentOrganization } = useAppContext();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: currentOrganization!.id } }); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const organization = data?.getOrganization;

  const showMRN = patientState.identifier && patientState.identifier.length < 36;

  const handleHidePatientForm = (): void => setShowPatientForm(false);
  const handleShowPatientForm = (): void => setShowPatientForm(true);
  const handleUpdatePatient = (patient: PatientData): void => setPatientState(patient);

  return (
    <Styles>
      <Card>
        <Card.Meta
          avatar={<Avatar size="large" shape="square" icon="user" />}
          title={
            <>
              {ptFormat.formatName(patientState)}{' '}
              {showMRN && (
                <FeatureGate feature={FeatureFlag.ShowMRN}>
                  <div className="sub-text">MRN: {patientState.identifier}</div>
                </FeatureGate>
              )}
            </>
          }
        />
        <Row>
          <Col span={24}>
            <ul style={{ marginTop: '1em' }}>
              <FactIcon type="calendar" text={ptFormat.formatDob(patientState.dob)} />
              <SexFactIcon sex={patientState.sex} />
            </ul>

            <ul>
              <FactIcon type="phone" text={formatPhone(patientState.phone) || 'Unknown Phone'} />
              <FactIcon type="mail" text={formatEmail(patientState.email) || 'Unknown Email'} />
            </ul>

            <PatientAddress
              address1={patientState.address1}
              address2={patientState.address2}
              city={patientState.city}
              state={patientState.state}
              postalCode={patientState.postalCode}
            />

            <FeatureGate feature={[FeatureFlag.ManuallyAddPatient, FeatureFlag.EditPatient]}>
              <div>
                <br />
                <Button icon="edit" type="primary" size="small" onClick={handleShowPatientForm}>
                  Edit
                </Button>
                <AddEditPatientForm
                  organization={organization}
                  patient={patientState}
                  show={showPatientForm}
                  handleHidePatientForm={handleHidePatientForm}
                  handleUpdatePatient={handleUpdatePatient}
                />
              </div>
            </FeatureGate>

            <Divider />

            <PatientInsurance
              networkName={coverage.network}
              planName={coverage.planName}
              groupId={coverage.groupId}
              policyId={coverage.planId}
              isPreauthorized={isPreauthorized}
            />
          </Col>
        </Row>
      </Card>
    </Styles>
  );
};

export const SexFactIcon: FC<{ sex: string | null }> = ({ sex }): JSX.Element => {
  let type = 'user';
  let text = 'Unknown Sex';

  // TODO: Clean this up
  switch (sex) {
    case 'male':
      type = 'man';
      text = 'Male';
      break;
    case 'female':
      type = 'woman';
      text = 'Female';
      break;
    default:
      type = 'user';
      text = 'Unknown Sex';
  }

  return <FactIcon type={type} text={text} />;
};

export const FactIcon: FC<{ type: string; text: string | null }> = ({ type, text }): JSX.Element => (
  <li>
    <Icon type={type} /> &nbsp; {text}
  </li>
);

interface PatientAddressProps {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export const PatientAddress: FC<PatientAddressProps> = ({
  address1 = 'Unknown Street',
  address2 = '',
  city = 'Unknown',
  state = 'Unknown',
  postalCode = '',
}): JSX.Element => {
  return (
    <Row>
      <Col span={2}>
        <Icon type="home" />
      </Col>
      <Col span={12}>
        <ul>
          <li>{formatTitleCase(address1) || 'Unknown'}</li>
          <li>{address2 || ''}</li>
          <li>{`${city ? `${formatTitleCase(city)},` : ''} ${state || ''} ${postalCode || ''}`}</li>
        </ul>
      </Col>
    </Row>
  );
};

interface PatientInsuranceProps {
  networkName: string | null;
  planName: string | null;
  groupId: string | null;
  policyId: string | null;
  isPreauthorized: boolean;
}

const PatientInsurance: FC<PatientInsuranceProps> = ({
  networkName,
  planName,
  groupId,
  policyId,
  isPreauthorized = false,
}): JSX.Element => {
  const insuranceTitle = (
    <Row type="flex" justify="space-between">
      <Col span={8}>
        <Title level={4}>Insurance</Title>
      </Col>
      <Col span={2} />
      <Col span={14}>
        <IsPreauthorizedTag isPreauthorized={isPreauthorized} />
      </Col>
    </Row>
  );

  const emptyPatientInsurance = (
    <span>Plan information unavaliable. The sender did not provide insurance information for this patient.</span>
  );

  const insuranceFields = [
    { title: 'Network', value: networkName },
    { title: 'Plan', value: planName },
    { title: 'Group ID', value: groupId },
    { title: 'Policy ID', value: policyId },
  ];

  // TODO: Make this a definition list
  // Align titles so they appear flush
  const filledPatientInsurance = (
    <Row type="flex">
      <Col span={18}>
        <ul>
          {insuranceFields
            .filter(f => !!f.value)
            .map(f => (
              <li key={f.title}>
                <Text strong={true}>{f.title}:</Text> {f.value}
              </li>
            ))}
        </ul>
        {!groupId && !policyId ? 'Plan details were not provided.' : ''}
      </Col>
    </Row>
  );

  return (
    <Fragment>
      {insuranceTitle}
      {networkName || planName || groupId || policyId ? filledPatientInsurance : emptyPatientInsurance}
    </Fragment>
  );
};

const IsPreauthorizedTag: FC<{ isPreauthorized: boolean | null }> = ({ isPreauthorized = false }): JSX.Element => {
  return isPreauthorized ? (
    <Tag color="green">
      <Icon type="check" />
      <span>&nbsp;Preauthorized</span>
    </Tag>
  ) : (
    <></>
    // <Tag color="gold">
    //   <Icon type="warning" />
    //   <span>&nbsp;Not Preauthorized</span>
    // </Tag>
  );
};

export default PatientCard;
