import { Button, Card, Form, message, Input, Typography } from 'antd';
import React, { FC, FormEvent } from 'react';
import { useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { useUpdateOrganizationConsumerSchedulingSettingsMutation } from '../../../hooks/mutations/useUpdateOrganizationConsumerSchedulingSettingsMutation';
import { FormComponentProps } from 'antd/lib/form';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  organizationId: string;
}

interface ConsumerSettingsFormData {
  footer: string;
  header: string;
  headTags: string;
}

const ConsumerTemplateCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateOrganizationConsumerSchedulingSettings] = useUpdateOrganizationConsumerSchedulingSettingsMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const organization = data?.getOrganization;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(async (err, values: ConsumerSettingsFormData) => {
      try {
        await updateOrganizationConsumerSchedulingSettings({
          variables: {
            organizationId: organizationId,
            updateOrganizationConsumerSchedulingSettingsInput: {
              htmlHeader: values.header ? values.header : null,
              htmlHeadTags: values.headTags ? values.headTags : null,
              htmlFooter: values.footer ? values.footer : null,
            },
          },
        });
        message.success('Your changes have been saved.');
      } catch (err) {
        message.error('An error occurred while saving your changes');
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card
        title="Template"
        extra={
          <BlockRestrictedUsers>
            <Button htmlType="submit" type="primary">
              Save Templates
            </Button>
          </BlockRestrictedUsers>
        }
      >
        <Form.Item label="HTML Header">
          <Typography.Text type="secondary">
            HTML added here will be rendered in the header of your consumer scheduling page and replace the default
            header.
          </Typography.Text>
          {form.getFieldDecorator('header', {
            initialValue: organization?.consumerSchedulingSettings?.htmlHeader,
          })(
            <Input.TextArea style={{ fontFamily: 'monospace', fontSize: 12 }} autoSize={{ minRows: 8, maxRows: 64 }} />
          )}
        </Form.Item>
        <Form.Item label="HTML Footer">
          <Typography.Text type="secondary">
            HTML added here will be rendered in the footer of your consumer scheduling page.
          </Typography.Text>
          {form.getFieldDecorator('footer', {
            initialValue: organization?.consumerSchedulingSettings?.htmlFooter,
          })(
            <Input.TextArea style={{ fontFamily: 'monospace', fontSize: 12 }} autoSize={{ minRows: 8, maxRows: 64 }} />
          )}
        </Form.Item>
        <Form.Item label="HTML Head Tags">
          <Typography.Text type="secondary">
            HTML added here will be rendered inside the &lt;head&gt; tag allowing additional customization.
          </Typography.Text>
          {form.getFieldDecorator('headTags', {
            initialValue: organization?.consumerSchedulingSettings?.htmlHeadTags,
          })(
            <Input.TextArea style={{ fontFamily: 'monospace', fontSize: 12 }} autoSize={{ minRows: 8, maxRows: 64 }} />
          )}
        </Form.Item>
      </Card>
    </Form>
  );
};

export const ConsumerTemplateCard = Form.create<Props>({ name: 'Template Settings' })(ConsumerTemplateCardComponent);
