import { Button, Col, Icon, List, Popconfirm, Row } from 'antd';
import React, { FC, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import FileReaderInput from 'react-file-reader-input';
import config from '../../../../config';
import { UploadReferralOrder } from '../../../../GraphQL';
import { useReferralRefetchContext } from '../../contexts/ReferralRefetchContext';
import { DeleteReferralOrder } from './DeleteReferralOrderMutation';
import { ReferralData, ReferralOrderData } from './GetReferralQuery';
import { ChangeReferralStatus } from './ChangeReferralStatus';

export const AttachmentsCard: FC<{ referral: ReferralData }> = ({ referral }): JSX.Element => {
  const [uploading, setUploading] = useState(false);
  const { refetch } = useReferralRefetchContext();
  return (
    <>
      <div>
        <Row>
          <Col xs={6} xl={12}>
            <ChangeReferralStatus referral={referral} key="change-referral-status" />
          </Col>
        </Row>
        <Row>
          <Col xs={6} xl={12}>
            <h4>Order &amp; Documentation</h4>
          </Col>
          <Col xs={2} xl={4}>
            <Mutation<{}, { file: File; referralId: string; type: string }>
              mutation={UploadReferralOrder}
              update={() => {
                refetch();
                setUploading(false);
              }}
            >
              {uploadReferralOrder => (
                <FileReaderInput
                  as="buffer"
                  onChange={(_event, results) => {
                    const [result] = results;
                    const file = result[1];
                    setUploading(true);
                    uploadReferralOrder({ variables: { file: file, referralId: referral.id, type: 'order' } });
                  }}
                >
                  <Button type="link" icon="file-add" loading={uploading} style={{ top: '-5px' }} />
                </FileReaderInput>
              )}
            </Mutation>
          </Col>
        </Row>
        {referral.referralOrder.length ? (
          <List
            size="small"
            dataSource={referral.referralOrder}
            locale={{ emptyText: ' ' }}
            renderItem={(a: ReferralOrderData) => {
              if (a.type !== 'order') {
                return <></>;
              }

              return (
                <List.Item>
                  <Icon type="file-pdf" theme="twoTone" />
                  <span style={{ fontSize: '12px' }}>
                    &nbsp;&nbsp;
                    <a href={`${config.endpoint}/download/referral/${a.id}/order`}>{a.displayFilename}</a>
                  </span>
                  <Mutation
                    mutation={DeleteReferralOrder}
                    update={() => {
                      refetch();
                    }}
                  >
                    {(deleteReferralOrder: (arg0: { variables: { id: string } }) => void) => (
                      <Popconfirm
                        title="Delete attachment?"
                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                        onConfirm={() => {
                          deleteReferralOrder({ variables: { id: a.id } });
                        }}
                      >
                        <Icon type="delete" style={{ color: 'lightgray', paddingLeft: 4 }} />
                      </Popconfirm>
                    )}
                  </Mutation>
                </List.Item>
              );
            }}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};
