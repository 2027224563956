import * as moment from 'moment';
import React, { FC, useState } from 'react';
import queryString, { ParsedQuery } from 'query-string';
import styled from 'styled-components';
import { AppointmentsCSVDownload } from '../components/AppointmentsCSVDownload';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { AppointmentsTable } from '../components/AppointmentsTable';
import { AuthorizeCSVExport } from '../../../components/AuthorizeCSVExport';
import { Col, PageHeader, Row } from 'antd';
import { FreeOrganizationProfilesCount } from '../../profiles/components/FreeOrganizationProfilesCount';
import { ReferralsDateRange } from '../../referrals/components/ReferralsTable/ReferralsDateRange';
import { SearchConsumerSchedulingPatients } from '../components/SearchConsumerSchedulingPatients';
import { useAppContext } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import { AppointmentOffersCSVDownload } from '../components/AppointmentOffersCSVDownload';
import { ViewerOrganization } from '../../viewer';

const Styles = styled.div`
  .main-text {
    font-size: 1em;
  }

  .sub-text {
    font-size: 0.8em;
    overflow-x: hidden;
  }
`;

export const AppointmentsPage: FC = (): JSX.Element => {
  const history = useHistory();

  const { currentOrganization } = useAppContext();
  const [patientId, setPatientId] = useState<string | undefined>();

  const queryParams: ParsedQuery = queryString.parse(history.location.search);
  const appointmentsContextProviderValue = {
    endDate:
      queryParams.endDate && typeof queryParams.endDate == 'string'
        ? queryParams.endDate
        : moment
            .default()
            .add(2, 'years')
            .toISOString(),
    filters: queryParams.filters ? queryParams.filters : '{}',
    pageNumber:
      queryParams.pageNumber && typeof queryParams.pageNumber == 'string' ? parseInt(queryParams.pageNumber, 10) : 1,
    pageSize: queryParams.pageSize && typeof queryParams.pageSize == 'string' ? parseInt(queryParams.pageSize, 10) : 10,
    patientId,
    startDate:
      queryParams.startDate && typeof queryParams.startDate == 'string'
        ? queryParams.startDate
        : moment
            .default()
            .subtract(2, 'years')
            .toISOString(),
  };

  const handleSelectPatient = (value: string | JSX.Element | undefined): void => {
    if (typeof value === 'string' || value === undefined) {
      setPatientId(value);
    }
  };

  const downloadOffersCSVButton = (currentOrganization: ViewerOrganization | null): JSX.Element | null => {
    if (currentOrganization && currentOrganization.useJumpq) {
      return (
        <Col>
          <AuthorizeCSVExport>
            <div style={{ marginLeft: '1em' }}>
              <AppointmentOffersCSVDownload />
            </div>
          </AuthorizeCSVExport>
        </Col>
      );
    } else {
      return null;
    }
  };

  return (
    <Styles>
      <Row>
        <Col span={16}>
          {currentOrganization && (
            <FreeOrganizationProfilesCount
              organizationId={currentOrganization.id}
              isFree={currentOrganization.isFree}
              freePlanConfiguration={currentOrganization.freePlanConfiguration}
            />
          )}
        </Col>
      </Row>

      <PageHeader
        title={
          <Row style={{ fontWeight: 'normal', width: '400px' }}>
            <Col>
              <SearchConsumerSchedulingPatients
                onSelect={handleSelectPatient}
                organizationId={currentOrganization ? currentOrganization.id : ''}
              />
            </Col>
          </Row>
        }
        extra={
          <Row type="flex">
            <Col>
              <ReferralsDateRange />
            </Col>
            <Col>
              <AuthorizeCSVExport>
                <div style={{ marginLeft: '1em' }}>
                  <AppointmentsCSVDownload />
                </div>
              </AuthorizeCSVExport>
            </Col>
            {downloadOffersCSVButton(currentOrganization)}
          </Row>
        }
      />
      <AppointmentsContext.Provider value={appointmentsContextProviderValue}>
        <AppointmentsTable />
      </AppointmentsContext.Provider>
    </Styles>
  );
};
