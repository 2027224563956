import { PageHeader, Tabs } from 'antd';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { FeatureFlag, FeatureGate } from '../../../helpers';
import { ChangePassword } from '../components/ChangePassword';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../modules/core/contexts/AppContext';
import { isEditor } from '../../../modules/core/components/PrivateRoute';

const Styles = styled.div`
  min-height: 80vh;

  .tabs-wrapper {
    height: 100%;
  }
`;

const getProfilesURL = (props: any): string => {
  const id = props.match.params.id;
  return `/organizations/${id}/profiles`;
};

export const SettingsContainer: FC<{}> = (props): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);

  const tabs = [
    <Tabs.TabPane tab="Change Password" key="1">
      <FeatureGate feature={FeatureFlag.HasSSO} fallbackContent={<ChangePassword />}>
        <p>You can change your password in your SSO portal.</p>
      </FeatureGate>
    </Tabs.TabPane>,
  ];

  if (isEditor(currentOrganization)) {
    tabs.push(
      <Tabs.TabPane tab="Edit your profiles" key="2">
        <div className="user-profiles-link">
          <Link to={getProfilesURL(props)}>Go to your profiles</Link>
        </div>
      </Tabs.TabPane>
    );
  }

  return (
    <Styles>
      <PageHeader title="Settings">
        <Tabs defaultActiveKey="1" tabPosition="left" className="tabs-wrapper">
          {tabs}
        </Tabs>
      </PageHeader>
    </Styles>
  );
};
