import { Card, Checkbox, Col, Input, Row } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { AppContext } from '../../core/contexts/AppContext';
import { FreeOrganizationProfilesCount } from '../components/FreeOrganizationProfilesCount';
import { ProfileTable } from '../components/ProfileTable';
import { NewProfileButton } from '../components/NewProfileButton';

const { Search } = Input;

export const Profiles: FC = (): JSX.Element => {
  const { currentOrganization } = useContext(AppContext);
  const [nameFilter, setNameFilter] = useState('');
  const [activeOnly, setActiveOnly] = useState(true);

  if (!currentOrganization) {
    return <></>;
  }

  // const newProfileLink = currentOrganization.isCovid ? 'new' : 'new';

  return (
    <Row>
      <Row type="flex">
        <Col span={24}>
          <FreeOrganizationProfilesCount
            organizationId={currentOrganization.id}
            isFree={currentOrganization.isFree}
            freePlanConfiguration={currentOrganization.freePlanConfiguration}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            title="Profiles"
            extra={[
              <Checkbox
                key="inactive"
                onChange={e => setActiveOnly(!e.target.value)}
                checked={activeOnly}
                value={activeOnly}
              >
                Active Only
              </Checkbox>,
              <Search
                key="search"
                placeholder="Filter by name"
                onChange={value => setNameFilter(value.target.value)}
                style={{ width: 200, paddingRight: 20 }}
              />,
              <NewProfileButton
                key="btn"
                organizationId={currentOrganization.id}
                isFree={currentOrganization.isFree}
                freePlanConfiguration={currentOrganization.freePlanConfiguration}
              />,
            ]}
          >
            <ProfileTable active={activeOnly} nameFilter={nameFilter} />
          </Card>
        </Col>
      </Row>
    </Row>
  );
};
