import { createLink } from 'apollo-absinthe-upload-link';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { InMemoryCache, NormalizedCacheObject, ApolloLink, ApolloClient } from '@apollo/client';
import config from './config';
import { getLoginToken } from './modules/auth';

const apolloUploadLink = createLink({ uri: config.endpoint + '/graphql' });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLoginToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export function makeClient(on401: () => void): ApolloClient<NormalizedCacheObject> {
  const link = onError(({ graphQLErrors }) => {
    if (graphQLErrors && process.env.NODE_ENV === 'development') {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations, null, 2)}, Path: ${path}`
        )
      );
    }

    if (
      graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].message &&
      graphQLErrors[0].message.replace('GraphQL error: ', '') === 'Unauthorized'
    ) {
      on401();
    }
  });

  const cache = new InMemoryCache({
    resultCacheMaxSize: Math.pow(2, 32),
    typePolicies: {
      Viewer: {
        fields: {
          organizations: {
            merge(existing, incoming) {
              const mergedMap = new Map();
              existing?.forEach((item: { __ref: string }) => mergedMap.set(item.__ref, item));
              incoming?.forEach((item: { __ref: string }) => mergedMap.set(item.__ref, item));
              return Array.from(mergedMap.values());
            },
          },
        },
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // window['cache'] = cache;

  const client = new ApolloClient<NormalizedCacheObject>({
    cache: cache,
    link: ApolloLink.from([link, authLink, apolloUploadLink]),
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // window['client'] = client;

  return client;
}
