import { Button, Col, Icon, Row, Typography } from 'antd';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { formatPhone } from '../../../helpers';
import { defaultTheme } from '../../../themes/default';
import { Profile } from '../hooks/useGetConsumerSchedulingProfileQuery';
import { Slot } from '../hooks/useGetConsumerSchedulingProfileSlotsQuery';
import { ProfileImage } from './ProfileImage';
import { ProfileShowMore } from './ProfileShowMoreInfo';
import { ProfileSlots } from './ProfileSlots';
import { ProfileWaitListSlots } from './ProfileWaitListSlots';

interface Props {
  dates: DateTime[];
  onSelectProfileSlot: (profile: Profile, slot: Slot) => void;
  procedureId?: string;
  profile: Profile & { mapNumber?: number };
  showMap: boolean;
  onClickFuture: () => void;
  onClickPast: () => void;
  handleJumpToDate: (arg0: DateTime) => void;
}

const { Title } = Typography;

const Styles = styled.div`
  .location-section {
    font-size: 12px;
    line-height: 1.2;
  }

  .distance-from-patient {
    color: ${({ theme }) => theme.bodyTextColor};
    font-style: italic;
    font-size: 12px;
    margin-bottom: 6px;
  }

  .map-number {
    position: absolute;
    background-color: ${props => props.theme.primaryColor};
    height: 24px;
    width: 24px;
    border-radius: 100%;
    color: white;
    top: -8px;
    left: -2px;
    border: 2px solid white;
    z-index: 2;

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .not-integrated-message {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    height: auto;
    line-height: 1;
    margin: 0;
    padding-top: 16px;
    text-align: center;
    text-decoration: none solid;
  }

  .profile-specialty {
    color: ${({ theme }) => theme.bodyTextColor};
    font-style: italic;
    font-size: 12px;
    margin-bottom: 6px;
  }

  .profile-display-name {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    height: auto;
    line-height: 1;
    margin: 0;
    text-decoration: none solid;
  }

  .section-content {
    font-size: 14px;
    color: ${({ theme }) => theme.bodyTextColor};
  }

  .section-title {
    font-weight: bold;
    font-size: 14px;
    color: ${({ theme }) => theme.bodyTextColor};
    padding-top: 16px;

    i {
      margin-right: 6px;
    }
  }

  .show-more-link {
    margin-top: 1em;
  }

  .select-procedure-message {
    color: ${({ theme }) => theme.blueDarkest};
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    height: auto;
    line-height: 1;
    margin: 0;
    max-width: 220px;
    text-align: center;
    text-decoration: none solid;
  }
`;

export const ConsumerSchedulingProfile: FC<Props> = ({
  dates,
  onSelectProfileSlot,
  procedureId,
  profile,
  showMap,
  onClickFuture,
  onClickPast,
  handleJumpToDate,
}): JSX.Element => {
  const handleSelectSlot = (slot: Slot): void => {
    onSelectProfileSlot(profile, slot);
  };

  const [viewSlots, setViewSlots] = useState(false);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Styles>
        <Row gutter={[0, { xs: 24, sm: 24, md: 24, lg: 0 }]}>
          <Col xs={24} lg={10}>
            <Row>
              <Col xs={0} lg={12} xl={10} xxl={9}>
                <div className="map-number">
                  <div>{profile.mapNumber}</div>
                </div>
                <ProfileImage profile={profile} />
              </Col>
              <Col xs={6} sm={4} md={3} lg={0}>
                <div className="map-number">
                  <div>{profile.mapNumber}</div>
                </div>
                <ProfileImage profile={profile} />
              </Col>
              <Col xs={18} sm={20} md={21} lg={12} xl={14} xxl={15}>
                <Title className="profile-display-name" level={3}>
                  {profile.displayName}
                </Title>
                {profile.location.name}
                <div className="profile-specialty">{profile.specialty}</div>
                <div className="distance-from-patient">
                  {profile.location.distanceFromPatient
                    ? `${_.round(parseFloat(profile.location.distanceFromPatient), 1)} miles away`
                    : null}
                </div>
                <Row>
                  <Col xs={0} lg={24}>
                    <div className="location-section">
                      <div className="section-title">
                        <Icon type="environment" />
                        {profile.location.name}
                      </div>
                      <div className="section-content">{profile.location.address1}</div>
                      {profile.location.address2 ? (
                        <div className="section-content">{profile.location.address2}</div>
                      ) : null}
                      <div className="section-content">
                        {profile.location.city}, {profile.location.state} {profile.location.postalCode}
                      </div>
                      <div className="section-content">{formatPhone(profile.phone)}</div>
                    </div>
                  </Col>
                </Row>
                <div className="show-more-link">
                  <ProfileShowMore profile={profile} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={14}>
            {profile.isWaitList && procedureId ? (
              <ProfileWaitListSlots
                dates={dates}
                procedureId={procedureId}
                onSelectSlot={handleSelectSlot}
                profile={profile}
                showMap={showMap}
              />
            ) : profile.isIntegrated ? (
              procedureId ? (
                viewSlots ? (
                  <ProfileSlots
                    dates={dates}
                    onSelectSlot={handleSelectSlot}
                    procedureId={procedureId}
                    profile={profile}
                    showMap={showMap}
                    onClickFuture={onClickFuture}
                    onClickPast={onClickPast}
                    handleJumpToDate={handleJumpToDate}
                  />
                ) : (
                  <Row type="flex" justify="center" align="middle" style={{ height: 200 }}>
                    <Col span={8}>
                      <Button type="primary" onClick={() => setViewSlots(true)}>
                        Click to see appointment availability
                      </Button>
                    </Col>
                  </Row>
                )
              ) : (
                <Row type="flex" justify="center" align="middle">
                  <Col className="select-procedure-message">
                    Select a visit type to see available appointment times.
                  </Col>
                </Row>
              )
            ) : (
              <Row type="flex" justify="center" align="middle">
                <Col className="not-integrated-message">
                  Please call {formatPhone(profile.phone)} to schedule an appointment.
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Styles>
    </ThemeProvider>
  );
};
