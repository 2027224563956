import {
  Card,
  Col,
  Comment,
  Divider,
  PageHeader,
  Row,
  Typography,
  List,
  Descriptions,
  Button,
  Form,
  Input,
  Tooltip,
} from 'antd';
import { gql } from '@apollo/client';
import React, { FC, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from '../../../components';
import { history } from '../../core/components/App/history';
import {
  AppointmentDetailsDisplayType,
  AppointmentsDetailsCard,
} from '../../referrals/components/Referral/AppointmentsDetailsCard';
import PatientCard from '../../referrals/components/Referral/PatientCard';
import { Appointment } from '../hooks/useListAppointmentsQuery';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import { AppointmentAttachmentList } from '../components/AppointmentAttachments';
import { questionnaireSaveAsPDF } from '../components/QuestionnaireSaveAsPDF';
import { AppContext } from '../../core/contexts/AppContext';

const { Text, Title } = Typography;

interface Data {
  getAppointment: Appointment;
}

interface Variables {
  id?: string;
}

const getAppointmentQuery = gql`
  query getAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      status
      comment
      rescheduledTo {
        id
        start
      }
      rescheduledFrom {
        id
        start
      }
      rescheduleLink
      tags {
        key
        value
      }
      referral {
        id
        provider {
          id
        }
      }
      patient {
        id
        identifier
        address1
        address2
        city
        state
        postalCode
        dob
        email
        familyName
        givenName1
        givenName2
        givenName3
        phone
        sex
        coverage {
          id
          network
          groupId
          planName
          planId
        }
      }
      start
      end
      procedure {
        id
        code
        display
        isUnspecified
        name
        specialty {
          id
          name
        }
      }
      profile {
        id
        displayName
        phone
        profileImgUrl
        location {
          id
          name
          address1
          address2
          city
          state
          postalCode
          distanceFromPatient
        }
        organization {
          id
        }
      }
      notes {
        id
        author
        body
        insertedAt
      }
      appointmentAttachments {
        id
        attachmentUrl
        displayFilename
      }
    }
  }
`;

const CreateReferral = gql`
  mutation CreateReferral(
    $groupIds: [ID!]!
    $patientId: ID!
    $patientIsPreAuthorized: Boolean
    $procedureId: ID!
    $providerId: ID!
  ) {
    createReferral(
      groupIds: $groupIds
      patientId: $patientId
      patientIsPreAuthorized: $patientIsPreAuthorized
      procedureId: $procedureId
      providerId: $providerId
    ) {
      id
    }
  }
`;

const AddAppointmentNotes = gql`
  mutation AddAppointmentNotes($id: ID!, $body: String!) {
    addAppointmentNotes(id: $id, body: $body) {
      id
      notes {
        id
        author
        body
        insertedAt
      }
    }
  }
`;

export const ApptPage: FC<FormComponentProps> = ({ form }): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [addApptNotes] = useMutation(AddAppointmentNotes);

  const { currentOrganization } = useContext(AppContext);
  const showButton = !currentOrganization?.referralSettings?.hideSchedulingButtons;

  const { data, error, loading } = useQuery<Data, Variables>(getAppointmentQuery, {
    variables: { id: id },
  });

  if (loading) return <Spinner />;
  if (error || !data || !data?.getAppointment) return <div>Error</div>;

  const appt = data.getAppointment;

  let notes = null;
  let answers = null;

  if (appt.comment) {
    const notesData = appt.comment.split('Questionnaire Answers:\n');

    notes = notesData[0];
    answers = notesData[1];
  }

  const handleNoteSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        form.resetFields();
        addApptNotes({
          variables: {
            id: appt.id,
            body: values.body,
          },
        });
      }
    });
  };

  const ScheduleFollowupButton: FC<{ appointment: Appointment }> = ({ appointment }): JSX.Element | null => {
    const [createReferral] = useMutation(CreateReferral);

    if (!appointment.patient.id) return null;

    if (appointment.referral) {
      const handleReferralClick = (): void => {
        const providerId = appointment?.referral?.provider?.id;
        const procedureId = appointment?.procedure.id;
        const patientId = appointment.patient.id;

        createReferral({
          variables: {
            groupIds: [],
            patientId: patientId,
            patientIsPreAuthorized: false,
            procedureId: procedureId,
            providerId: providerId,
          },
        }).then(result => {
          history.push(`/referrals/${result.data.createReferral.id}/search`);
        });
      };

      return (
        <Button type="primary" onClick={handleReferralClick}>
          Schedule Follow Up
        </Button>
      );
    } else {
      return (
        <Link to={`/referrals/new?patientId=${appt.patient.id}`} className="ant-btn ant-btn-primary">
          Schedule Follow Up
        </Link>
      );
    }
  };

  return (
    <>
      <PageHeader
        title="Appointment"
        onBack={() => {
          history.goBack();
        }}
        extra={showButton ? <ScheduleFollowupButton appointment={appt} /> : null}
      />
      <Row gutter={16} type="flex" style={{ paddingLeft: '32px', paddingRight: '32px' }}>
        <Col span={6} style={{ height: '100%' }}>
          <PatientCard
            patient={appt.patient}
            coverage={{
              id: appt.patient.coverage?.id ? appt.patient.coverage.id : null,
              network: appt.patient.coverage?.network ? appt.patient.coverage.network : null,
              groupId: appt.patient.coverage?.groupId ? appt.patient.coverage.groupId : null,
              planName: appt.patient.coverage?.planName ? appt.patient.coverage.planName : null,
              planId: appt.patient.coverage?.planId ? appt.patient.coverage.planId : null,
            }}
            isPreauthorized={false}
          />
        </Col>
        <Col span={18}>
          <Card>
            <AppointmentsDetailsCard appointment={appt} cardType={AppointmentDetailsDisplayType.ConsumerAppointment} />
            <Divider />
            <Title level={4}>Patient Notes</Title>
            <Text>
              {!notes && <p style={{ color: '#bbb', fontStyle: 'italic' }}>No patient notes were added</p>}
              {notes &&
                notes.split('\n').map((item, key) => (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                ))}
              {answers && (
                <>
                  <div id="questionnaire-answers">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4>Questionnaire Answers</h4>
                      <Button type="primary" onClick={() => questionnaireSaveAsPDF('appt', appt.id)}>
                        Save as PDF
                      </Button>
                    </div>
                    <Descriptions bordered size="small" column={1}>
                      {answers.split('\n').map(v => {
                        if (v.trim() === '') return null;
                        if (v.split(':', 2).length !== 2) {
                          return (
                            <Descriptions.Item key="notes" label={<span style={{ fontWeight: 800 }}>Notes</span>}>
                              {v}
                            </Descriptions.Item>
                          );
                        }

                        const components = v.split(':');
                        const question = components.shift();
                        const answer = components.join(':');
                        return (
                          <Descriptions.Item key={v} label={<span style={{ fontWeight: 800 }}>{question}</span>}>
                            {answer}
                          </Descriptions.Item>
                        );
                      })}
                    </Descriptions>
                  </div>
                </>
              )}
            </Text>
            <Divider />
            <Row>
              <Col span={11}>
                <AppointmentAttachmentList appt={appt} />
              </Col>
              <Col push={1} span={12}>
                {appt.tags.length > 0 ? (
                  <Descriptions bordered size="small" column={1}>
                    {appt.tags.map(({ key, value }) => (
                      <Descriptions.Item
                        key={key}
                        label={<span style={{ fontFamily: 'monospace', fontSize: 12, fontWeight: 800 }}>{key}</span>}
                      >
                        {value}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                ) : null}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Title level={4}>Staff Notes &amp; Results</Title>
                <Col span={20}>
                  <div>
                    <List
                      className="comment-list"
                      itemLayout="horizontal"
                      dataSource={appt.notes}
                      locale={{
                        emptyText: (
                          <span style={{ color: '#bbb', fontStyle: 'italic' }}>No staff notes were added</span>
                        ),
                      }}
                      renderItem={item => (
                        <li>
                          <Comment
                            author={<b>{item.author}</b>}
                            content={item.body.split('\n').map((line, key) => (
                              <span key={key}>
                                {line}
                                <br />
                              </span>
                            ))}
                            datetime={
                              <Tooltip
                                title={moment
                                  .utc(item.insertedAt)
                                  .local()
                                  .format('MMMM Do YYYY, h:mm:ssA')}
                              >
                                {moment
                                  .utc(item.insertedAt)
                                  .local()
                                  .fromNow()}
                              </Tooltip>
                            }
                          />
                        </li>
                      )}
                    />
                  </div>
                  <Form onSubmit={handleNoteSubmit}>
                    <Form.Item>
                      {form.getFieldDecorator('body', {
                        rules: [{ required: true, message: 'required' }],
                        initialValue: '',
                      })(<Input.TextArea rows={6} placeholder="Enter any details here..." />)}
                    </Form.Item>

                    <Button htmlType="submit" type="primary" size="large">
                      Add Notes
                    </Button>
                  </Form>
                </Col>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export const AppointmentPage = Form.create<FormComponentProps>({ name: 'appt_notes_form' })(ApptPage);
