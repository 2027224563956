import { Col, Modal, Row, Spin } from 'antd';
import { gql } from '@apollo/client';
import queryString from 'query-string';
import React, { createContext, useContext, useRef, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { Spinner } from '../../../components';
import { history } from '../../core/components/App/history';
import { AppContext } from '../../core/contexts/AppContext';
import SearchWizard from './SearchWizard';

const SearchSchedulesQuery = gql`
  query(
    $referralId: ID!
    $page: Int
    $filters: String
    $textFilter: String
    $sort: SchedulingSortEnum
    $distanceFromPatient: Int
  ) {
    searchSchedules(
      referralId: $referralId
      page: $page
      filters: $filters
      textFilter: $textFilter
      sort: $sort
      distanceFromPatient: $distanceFromPatient
    )
      @connection(
        key: "SearchSchedules"
        filter: ["referralId", "page", "filters", "textFilter", "distanceFromPatient", "sort"]
      ) {
      filters
      pageNumber
      pageSize
      totalEntries
      totalPages
      entries {
        id
        profile {
          id
          displayName
          type
          phone
          profileImgUrl
          professionalStatementSafe
          boardCertificationsSafe
          educationTrainingSafe
          npiNumber
          specialty
          isIntegrated
          isSendToProvider
          isSendToPatient
          nextAvailability
          referralSearchNoticeSafe
          maintenanceMode
          languages {
            code
            name
          }
          location {
            id
            name
            address1
            address2
            city
            state
            phone
            postalCode
            distanceFromPatient
            distanceFromProvider
            latitude
            longitude
          }
          organization {
            id
            name
            referralSettings {
              isDiagnosisRequired
              isNoteRequired
            }
          }
        }
        insuranceNetwork
        insuranceConfidence
      }
    }

    getReferral(id: $referralId) {
      id
      description
      patientIsPreAuthorized
      appointment {
        id
        profile {
          id
          displayName
        }
      }
      diagnoses {
        id
        code
        description
      }
      orders: referralOrder {
        id
        displayFilename
        orderUrl
      }
      tasks {
        id
        status
        viewerIsOwner
        startedAt
        finishedAt
        insertedAt
        user {
          id
          name
        }
        workflowQueue {
          id
          name
          color
        }
      }
      patient {
        id
        identifier
        givenName1
        givenName2
        givenName3
        familyName
        sex
        dob
        address1
        address2
        city
        state
        postalCode
        phone
        email
      }
      patientReferral {
        id
        profile {
          displayName
        }
      }
      profileReferral {
        id
        profile {
          displayName
        }
      }
      provider {
        id
        name
      }
      coverage {
        id
        network
        groupId
        planName
        planId
      }
      procedure {
        id
        name
        specialty {
          id
          name
          isOrderRequired
          isDiagnosisRequired
        }
      }
      groups {
        id
        name
      }
      tags {
        key
        value
      }
      questionnaireAnswerSets {
        id
        questionnaireName
        questionnaireAnswers {
          id
          text
          value
        }
      }
    }
  }
`;

const getInNetworkFromQueryString = qs => {
  const qsArr = qs.split('&');

  if (qsArr && qsArr.length > 0) {
    return qsArr[0].split('=')[1];
  }

  return 'all';
};

const getPayorPlanFromQueryString = qs => {
  const payorStringArr = qs.split('&');

  if (payorStringArr.length > 1) {
    return payorStringArr[1].split('=')[1];
  }

  return null;
};

const isSortingByAvailability = () => {
  const queryParams = queryString.parse(history.location.search);
  return queryParams.sort && queryParams.sort === 'AVAILABILITY';
};

export const SearchWizardContainerContext = createContext();

export const SearchWizardContainer = ({ match, location }) => {
  const { currentOrganization } = useContext(AppContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [profileSlotCounts, setProfileSlotCounts] = useState({});
  const alreadyRerendered = useRef(false);
  const [textFilter, setTextFilter] = useState('');
  const [distanceFromPatient, setDistanceFromPatient] = useState(currentOrganization.defaultReferralSearchRadius);

  const { filters, page, sort, taskId, bufferInsurance } = queryString.parse(history.location.search);
  const profileSlotCountsHolder = useRef({});

  let defaultSort = 'DISTANCE';

  switch (currentOrganization.defaultReferralSort) {
    case 'distance':
      defaultSort = 'DISTANCE';
      break;

    case 'next_availability':
      defaultSort = 'NEXT_AVAILABILITY';
      break;

    default:
      defaultSort = 'DISTANCE';
      break;
  }

  const queryParams = {
    page: page ? parseInt(page) : 1,
    filters,
    sort: sort || defaultSort,
  };

  const clearAlreadyRerendered = () => {
    alreadyRerendered.current = false;
  };

  const triggerRerender = () => {
    if (!alreadyRerendered.current) {
      alreadyRerendered.current = true;
      setModalVisible(true);
      setTimeout(() => {
        // do update
        setProfileSlotCounts(profileSlotCountsHolder.current);
        setModalVisible(false);
      }, 10000);
    }
  };

  const updateProfileSlotCount = (profileId, slotCount) => {
    if (profileSlotCountsHolder.current[profileId] !== slotCount) {
      profileSlotCountsHolder.current = { ...profileSlotCountsHolder.current, [profileId]: slotCount };
    }
  };

  return (
    <Query
      query={SearchSchedulesQuery}
      variables={{
        referralId: match.params.id,
        network: getInNetworkFromQueryString(location.search),
        payorPlanId: getPayorPlanFromQueryString(location.search),
        textFilter: textFilter,
        distanceFromPatient: distanceFromPatient,
        ...queryParams,
      }}
    >
      {({ loading, error, data }) => {
        // Show a spinner for the first load, but not for any filter changes (i.e, data already exists). Otherwise
        // the entire page gets re-rendered between filter state changes.
        if (loading && !data) return <Spinner />;
        if (error) return <div>Error</div>;

        const { searchSchedules, getReferral } = data;
        const profiles = searchSchedules && searchSchedules.entries ? searchSchedules.entries : [];
        const profilesWithUpdatedSlotCounts = profiles.map(entry => ({
          ...entry,
          profile: { ...entry.profile, slotCount: profileSlotCounts[entry.profile.id] || 0 },
        }));
        // const allProfiles = Scheduling.searchSchedules

        const wizard = (
          <SearchWizardContainerContext.Provider
            value={{
              referralId: getReferral.id,
              searchSchedules,
              profilesWithUpdatedSlotCounts,
              isSortingByAvailability: isSortingByAvailability(),
              updateProfileSlotCount,
              triggerRerender,
              clearAlreadyRerendered,
              textFilter,
              setTextFilter,
              distanceFromPatient,
              setDistanceFromPatient,
              defaultSort,
              taskId,
              bufferInsurance,
            }}
          >
            <Modal visible={modalVisible} title={null} footer={null} closable={false}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
                <h2>Finding Available Slots</h2>
                <div style={{ marginTop: '15px' }}>
                  <Spin size="large" />
                </div>
              </div>
            </Modal>
            <Row type="flex" justify="center">
              <Col span={23}>
                <SearchWizard getReferral={getReferral} currentOrganization={currentOrganization} />
              </Col>
            </Row>
          </SearchWizardContainerContext.Provider>
        );

        if (loading && !modalVisible) {
          return <Spin>{wizard}</Spin>;
        } else {
          return wizard;
        }
      }}
    </Query>
  );
};
