import React, { FC } from 'react';
import { Empty } from 'antd';
import { GoogleMap, GoogleMapsMarker } from '../../../components';

interface Props {
  profiles: Profile[];
}

interface Profile {
  id: string;
  displayName: string;
  mapNumber: number;
  location: {
    id: string;
    latitude: number;
    longitude: number;
  };
}

type Map = google.maps.Map;
type GoogleMapsApi = typeof google.maps;
type LatLongBounds = google.maps.LatLngBounds;

const defaultZoomLevel = 10;

// Return map bounds based on list of places
const getMapBounds = (map: Map, maps: GoogleMapsApi, places: Profile[]): LatLongBounds => {
  const bounds = new google.maps.LatLngBounds();

  places.forEach(place => {
    bounds.extend(new maps.LatLng(place.location.latitude, place.location.longitude));
  });
  return bounds;
};

// Set zoom level when map contains a single location. This avoids zooming in so
// much that the user has to zoom out to gain any value from the map.
const maybeSetZoomForSingleLocation = (map: Map, places: Profile[]): void => {
  if (!places) return;

  if (places.length === 1) {
    map.setZoom(defaultZoomLevel);
  } else if (places.length > 1) {
    const locationIds = places.map(x => x.location.id);
    const uniqueLocationIds = new Set(locationIds);
    if (uniqueLocationIds.size === 1) map.setZoom(defaultZoomLevel);
  }
};

// Re-center map when resizing the window
const bindResizeListener = (map: Map, maps: GoogleMapsApi, bounds: LatLongBounds, places: Profile[]): void => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
      maybeSetZoomForSingleLocation(map, places);
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map: Map, maps: GoogleMapsApi, places: Profile[]): void => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds, places);

  maybeSetZoomForSingleLocation(map, places);
};

export const ConsumerSchedulingMap: FC<Props> = ({ profiles }) => {
  const places = profiles.filter(p => p.location && p.location.latitude && p.location.longitude);

  return (
    <>
      {places && places.length > 0 ? (
        <GoogleMap
          style={{ height: '400px' }}
          defaultZoom={10}
          defaultCenter={{ lat: 34.0522, lng: 118.2437 }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
        >
          {places.map(place => (
            <GoogleMapsMarker
              key={place.id}
              number={place.mapNumber}
              lat={place.location.latitude}
              lng={place.location.longitude}
              label={place.displayName}
            />
          ))}
        </GoogleMap>
      ) : (
        <div
          style={{
            height: '400px',
            border: '1px solid #dae1e7',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Empty description="No Map Data" />
        </div>
      )}
    </>
  );
};
