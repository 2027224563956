import { PageHeader, Tabs, Icon } from 'antd';
import React, { FC, useContext } from 'react';
import { AppContext } from '../../core/contexts/AppContext';

import { BillingSettingsCard } from '../components/BillingSettingsCard';
import config from '../../../config';
import { EmailSettingsCard } from '../components/EmailSettingsCard';
import { EditOrganization } from './EditOrganization';
import { SecuritySettingsCard } from './SecuritySettings';
import { SuperuserOrgSettingsContainer } from './SuperuserOrgSettings';
import { ConsumerSettingsCard } from '../components/ConsumerSettingsCard';
import { useQueryParam, StringParam } from 'use-query-params';
import { isBlockitAdmin } from '../../core/components/PrivateRoute';
import { ReferralSettingsCard } from '../components/ReferralSettingsCard';
import { AppointmentSettingsCard } from '../components/AppointmentSettingsCard';

export const OrgSettingsPage: FC<{}> = (): JSX.Element | null => {
  const { currentOrganization, switchMode } = useContext(AppContext);

  switchMode('admin');

  const [tab, setTab] = useQueryParam('tab', StringParam);
  const defaultTab = tab || 'profile';

  if (!currentOrganization) return null;

  return (
    <PageHeader title="Settings">
      <Tabs
        tabPosition="left"
        defaultActiveKey={defaultTab}
        onChange={setTab}
        onTabClick={(key: string) => {
          if (key === 'notifications') {
            window.open(`${config.endpoint}/organizations/${currentOrganization.id}/notifications`);
          } else if (key === 'bookingLog') {
            window.open(`${config.endpoint}/organizations/${currentOrganization.id}/integration/proposals`);
          } else if (key === 'actions') {
            window.open(`${config.endpoint}/organizations/${currentOrganization.id}`);
          }
        }}
      >
        <Tabs.TabPane tab="Organization" key="organization">
          <EditOrganization />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Billing" key="billing">
          <BillingSettingsCard organizationId={currentOrganization.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Appointment Settings" key="appointments">
          <AppointmentSettingsCard organizationId={currentOrganization.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Email Notifications" key="emailTemplates">
          <EmailSettingsCard organizationId={currentOrganization.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Referral Settings" key="referral">
          <ReferralSettingsCard organizationId={currentOrganization.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Consumer Settings" key="consumer">
          <ConsumerSettingsCard organizationId={currentOrganization.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Security" key="security">
          <SecuritySettingsCard organizationId={currentOrganization.id} />
        </Tabs.TabPane>
        {isBlockitAdmin(currentOrganization) && <Tabs.TabPane tab="Notifications" key="notifications"></Tabs.TabPane>}
        <Tabs.TabPane tab="Appointment Booking Log" key="bookingLog"></Tabs.TabPane>
        <Tabs.TabPane tab="Actions" key="actions"></Tabs.TabPane>
        {isBlockitAdmin(currentOrganization) && (
          <Tabs.TabPane
            tab={
              <>
                <Icon type="lock" />
                Feature Flags
              </>
            }
            key="account"
          >
            <SuperuserOrgSettingsContainer organizationId={currentOrganization.id} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </PageHeader>
  );
};
