import { gql } from '@apollo/client';
import { MutationTuple, useMutation } from '@apollo/client';

interface UpdateOrganizationData {
  UpdateOrganization: Organization;
}

interface UpdateOrganizationVariables {
  organizationId: string;
  updateOrganizationInput: {
    name?: string;
    profileImgFile?: File | null;
    customEmailText?: string;
    customEmailSubject?: string;
    maxInactiveUserDays?: number;
    slug?: string;
    showInsuranceFilters?: boolean;
    showInsuranceFiltersDuringScheduling?: boolean;
    searchProfilesWithoutScheduling?: boolean;
    sendPatientNotifications?: boolean;
    patientEmailFromAddress?: string;
    showSendToPatient?: boolean;
    enablePatientCancellation?: boolean;
    enablePatientRescheduling?: boolean;
    defaultReferralSearchRadius?: number | null;
    defaultReferralSort?: string;
  };
}

interface Organization {
  id: string;
  name: string;
  profileImgUrl: string;
  customEmailText: string;
  customEmailSubject: string;
  maxInactiveUserDays: number;
  slug: string | null;
  isActive: boolean;
  featureFlags: FeatureFlags[];
  region: Region;
  showInsuranceFilters: boolean;
  showInsuranceFiltersDuringScheduling: boolean;
  searchProfilesWithoutScheduling: boolean;
  sendPatientNotifications: boolean;
  patientEmailFromAddress: string;
  showSendToPatient: boolean;
  enablePatientCancellation: boolean;
  enablePatientRescheduling: boolean;
  defaultReferralSearchRadius?: number | null;
  defaultReferralSort?: string;
}

interface Region {
  id: string;
  name: string;
}

interface FeatureFlags {
  id: string;
  name: string;
}

const UpdateOrganizationMutation = gql`
  mutation UpdateOrganization($organizationId: ID!, $updateOrganizationInput: UpdateOrganizationInput!) {
    updateOrganization(organizationId: $organizationId, updateOrganizationInput: $updateOrganizationInput) {
      id
      name
      customEmailText
      customEmailSubject
      maxInactiveUserDays
      profileImgUrl
      slug
      isActive
      showInsuranceFilters
      showInsuranceFiltersDuringScheduling
      searchProfilesWithoutScheduling
      sendPatientNotifications
      patientEmailFromAddress
      showSendToPatient
      defaultReferralSearchRadius
      defaultReferralSort
      featureFlags {
        id
        name
      }
      region {
        id
        name
      }
    }
  }
`;

type UpdateOrganizationMutationResult = MutationTuple<UpdateOrganizationData, UpdateOrganizationVariables>;

export const useUpdateOrganizationMutation = (): UpdateOrganizationMutationResult => {
  return useMutation<UpdateOrganizationData, UpdateOrganizationVariables>(UpdateOrganizationMutation);
};
