import { createContext } from 'react';
import { Viewer, ViewerLocation, ViewerOrganization } from '../../viewer';

export interface AppContextInterface {
  currentLocation: ViewerLocation | null;
  currentOrganization: ViewerOrganization | null;
  init: (viewer: Viewer) => void;
  isAuthenticated: boolean;
  mode: 'referral' | 'admin';
  reset: () => void;
  setCurrentLocation: (location: ViewerLocation | null) => void;
  setCurrentOrganization: (organization: ViewerOrganization | null) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setMode: (mode: 'referral' | 'admin') => void;
  setViewer: (viewer: Viewer | null) => void;
  switchMode: (mode: 'referral' | 'admin') => void;
  viewer: Viewer | null;
}

export const AppContext = createContext<AppContextInterface>({
  currentLocation: null,
  currentOrganization: null,
  init: () => {},
  isAuthenticated: false,
  mode: 'referral',
  reset: () => {},
  setCurrentLocation: () => {},
  setCurrentOrganization: () => {},
  setIsAuthenticated: () => {},
  setMode: () => {},
  setViewer: () => {},
  switchMode: () => {},
  viewer: null,
});

export const AppContextConsumer = AppContext.Consumer;
