import React, { FC, FormEvent } from 'react';
import { Button, Card, Form, message, Typography, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Input } from '../../../system/components/Input';
import { useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { useUpdateOrganizationConsumerSchedulingSettingsMutation } from '../../../hooks/mutations/useUpdateOrganizationConsumerSchedulingSettingsMutation';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  organizationId: string;
}

interface ConsumerSettingsFormData {
  googleTagManagerId: string;
}

const ConsumerOtherCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateOrganizationConsumerSchedulingSettings] = useUpdateOrganizationConsumerSchedulingSettingsMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const organization = data?.getOrganization;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(async (err, values: ConsumerSettingsFormData) => {
      try {
        await updateOrganizationConsumerSchedulingSettings({
          variables: {
            organizationId: organizationId,
            updateOrganizationConsumerSchedulingSettingsInput: {
              googleTagManagerId: values.googleTagManagerId ? values.googleTagManagerId : null,
            },
          },
        });
        message.success('Your changes have been saved.');
      } catch (err) {
        message.error('An error occurred while saving your changes');
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card
        title="Other Settings"
        extra={
          <BlockRestrictedUsers>
            <Button htmlType="submit" type="primary">
              Save Other Settings
            </Button>
          </BlockRestrictedUsers>
        }
      >
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Google Tag Manager ID">
              {form.getFieldDecorator('googleTagManagerId', {
                initialValue: organization?.consumerSchedulingSettings?.googleTagManagerId,
              })(<Input placeholder="GTM-XXXXXXX" />)}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Adding a Google Tag Manager ID will allow use of Google Tag Manager on the consumer scheduling pages.
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export const ConsumerOtherCard = Form.create<Props>({ name: 'Other Settings' })(ConsumerOtherCardComponent);
