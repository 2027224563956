import { Col, Form, Row, Steps } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';
import AddInfoStep from '../components/SearchWizard/pages/AddInfoStep';
import { ConfirmStep } from '../components/SearchWizard/pages/ConfirmStep';
import { SearchStep } from '../components/SearchWizard/pages/SearchStep';

const Step = Steps.Step;

const Styles = styled.div`
  .steps-banner {
    margin-bottom: 2rem;
  }

  .steps-nav-buttons {
    margin-bottom: 4rem;
  }

  .steps-content {
    margin-top: 2rem;
  }
`;

class StepsWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      selectedTimeSlot: null,
      sendToPatient: false,
      sendToProvider: false,
    };
  }

  handleSelectTimeSlot = timeSlot => {
    this.setState({ selectedTimeSlot: timeSlot, sendToPatient: false, sendToProvider: false }, () => this.next());
  };

  handleSendToPatient = profile => {
    this.setState({ selectedTimeSlot: { profile }, sendToPatient: true, sendToProvider: false }, () => this.next());
  };

  handleSendToProvider = profile => {
    this.setState({ selectedTimeSlot: { profile }, sendToPatient: false, sendToProvider: true }, () => this.next());
  };

  steps = [
    {
      title: 'Search',
      content: () => (
        <SearchStep
          {...this.props}
          handleSelectTimeSlot={this.handleSelectTimeSlot}
          handleSendToPatient={this.handleSendToPatient}
          handleSendToProvider={this.handleSendToProvider}
        />
      ),
    },
    {
      title: 'Referral Information',
      content: state => (
        <AddInfoStep
          {...this.props}
          selectedTimeSlot={state.selectedTimeSlot}
          sendToPatient={state.sendToPatient}
          sendToProvider={state.sendToProvider}
          form={this.props.form}
          prev={this.prev}
          next={this.next}
        />
      ),
    },
    {
      title: 'Confirm',
      content: () => (
        <ConfirmStep
          {...this.props}
          timeSlot={this.state.selectedTimeSlot}
          sendToPatient={this.state.sendToPatient}
          sendToProvider={this.state.sendToProvider}
          formValues={this.props.form.getFieldsValue()}
          prev={this.prev}
        />
      ),
    },
  ];

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <Styles>
        <Row type="flex" justify="center" className="steps-banner">
          <Col span={12}>
            <Steps current={current}>
              {this.steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </Col>
        </Row>

        <div className="steps-content">{this.steps[current].content(this.state)}</div>
      </Styles>
    );
  }
}

export default Form.create({ name: 'Create Appointment Form' })(StepsWizard);
