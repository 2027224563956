import { gql } from '@apollo/client';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

export const getOrganization = gql`
  query GetOrganization($organizationId: ID!) {
    getOrganization(organizationId: $organizationId) {
      id
      name
      customEmailText
      customEmailSubject
      maxInactiveUserDays
      profileImgUrl
      slug
      isActive
      showInsuranceFilters
      showInsuranceFiltersDuringScheduling
      searchProfilesWithoutScheduling
      sendPatientNotifications
      patientEmailFromAddress
      showSendToPatient
      enablePatientCancellation
      enablePatientRescheduling
      defaultReferralSearchRadius
      defaultReferralSort
      featureFlags {
        id
        name
      }
      region {
        id
        name
      }
      consumerSchedulingSettings {
        id
        allowAttachments
        searchInsurance
        visitTypeFormLabel
        htmlHeader
        htmlFooter
        htmlHeadTags
        googleTagManagerId
        showLanguages
        searchProviders
        showPatientPrefill
        showMap
        noAvailabilityText
        noAvailabilityAction
        formFieldSettings {
          field
          required
        }
      }
      freePlanConfiguration {
        id
        name
        appointmentsPerWeek
        locations
        profiles
        providers
        users
      }
      locations {
        id
        name
        address1
        city
        state
        postalCode
        isActive
      }
    }
  }
`;

interface Organization {
  id: string;
  name: string;
  customEmailText: string;
  customEmailSubject: string;
  maxInactiveUserDays: number;
  profileImgUrl: string;
  slug: string | null;
  isActive: boolean;
  featureFlags: FeatureFlags[];
  region: Region;
  consumerSchedulingSettings?: OrganizationConsumerSchedulingSettings;
  showInsuranceFilters: boolean;
  showInsuranceFiltersDuringScheduling: boolean;
  searchProfilesWithoutScheduling: boolean;
  sendPatientNotifications: boolean;
  patientEmailFromAddress: string;
  locations: Location[];
  showSendToPatient: boolean;
  enablePatientCancellation: boolean;
  enablePatientRescheduling: boolean;
  defaultReferralSearchRadius: number | null;
  defaultReferralSort: string;
}

interface OrganizationConsumerSchedulingSettings {
  id: string;
  allowAttachments: boolean;
  searchInsurance: boolean;
  visitTypeFormLabel: string | null;
  htmlHeader: string | null;
  htmlFooter: string | null;
  htmlHeadTags: string | null;
  googleTagManagerId: string | null;
  showLanguages: boolean;
  searchProviders: boolean;
  showPatientPrefill: boolean;
  sendPatientNotifications: boolean;
  showMap: boolean;
  patientEmailFromAddress: string;
  formFieldSettings: [{ field: string; required: boolean }] | [];
  noAvailabilityText: string | null;
  noAvailabilityAction: string | null;
}

interface Region {
  id: string;
  name: string;
}

interface FeatureFlags {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
  address1: string;
  city: string;
  state: string;
  postalCode: string;
  isActive: boolean;
}

interface Data {
  getOrganization: Organization;
}

type Variables = {
  organizationId: string;
};

type Options = QueryHookOptions<Data, Variables>;

type Result = QueryResult<Data, Variables>;

export const useGetOrganizationQuery = (options: Options): Result => {
  return useQuery<Data, Variables>(getOrganization, options);
};
