import React from 'react';
import { PrivateRoute } from '../core';
import AvailabilityManagement from './pages/AvailabilityManagement';

export const routes = [
  <PrivateRoute
    exact
    path="/organizations/:organizationId/availabilityManagement"
    key="route--availability_management"
    component={AvailabilityManagement}
  />,
];
