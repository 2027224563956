import { Button, Card, Col, Form, Input, message, Row, Switch, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, FormEvent } from 'react';
import { useUpdateOrganizationMutation } from '../../../hooks/mutations/useUpdateOrganization';
import { useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { BlockRestrictedUsers } from '../../../helpers';
import { GetViewerOrganization } from '../../../hooks/queries/useGetViewerOrganizationQuery';

interface Props extends FormComponentProps {
  organizationId: string;
}

const EmailSettingsCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateOrganization] = useUpdateOrganizationMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(
      async (
        err,
        values: {
          customEmailText: string;
          customEmailSubject: string;
          sendPatientNotifications: boolean;
          patientEmailFromAddress: string;
        }
      ) => {
        try {
          await updateOrganization({
            variables: {
              organizationId: organizationId,
              updateOrganizationInput: {
                customEmailText: values.customEmailText,
                customEmailSubject: values.customEmailSubject,
                sendPatientNotifications: values.sendPatientNotifications,
                patientEmailFromAddress: values.patientEmailFromAddress,
              },
            },
            refetchQueries: [{ query: GetViewerOrganization, variables: { organizationId: organizationId } }],
          });
          message.success('Your changes have been saved.');
        } catch (err) {
          message.error('An error occurred while saving your changes');
        }
      }
    );
  };

  console.log(data?.getOrganization);

  return (
    <Row>
      <Col span={18} push={1}>
        <Form onSubmit={handleSubmit}>
          <Card
            title="Email Templates"
            extra={
              <BlockRestrictedUsers>
                <Button htmlType="submit" type="primary">
                  Save Templates
                </Button>
              </BlockRestrictedUsers>
            }
          >
            <Row align="top">
              <Col span={12}>
                <Form.Item label="Send Patient Notification Emails?">
                  {form.getFieldDecorator('sendPatientNotifications', {
                    valuePropName: 'checked',
                    initialValue: data?.getOrganization?.sendPatientNotifications,
                  })(<Switch />)}
                </Form.Item>
              </Col>
              <Col span={10} push={1}>
                <Typography.Text type="secondary">Enable/disable patient notification emails</Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Patient Email From Address">
                  {form.getFieldDecorator('patientEmailFromAddress', {
                    initialValue: data?.getOrganization?.patientEmailFromAddress,
                  })(<Input disabled placeholder="no-reply@blockitnow.com" />)}
                </Form.Item>
              </Col>
              <Col span={10} push={1}>
                <Typography.Text type="secondary">
                  Changes the from address of the patient notification email sent by Blockit.
                  <br />
                  <br />
                  <b>
                    NOTE: Do not change this until your are sent a DKIM key by Blockit. Failure to do so will result in
                    bounced patient emails.
                  </b>
                </Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="Patient Confirmation Email Subject">
                  {form.getFieldDecorator('customEmailSubject', {
                    initialValue: data?.getOrganization?.customEmailSubject,
                  })(<Input placeholder="Custom subject line for all patient emails" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Patient Email Footer">
                  <Typography.Text type="secondary">
                    This text will appear at the bottom of all email notifications to the patient.
                  </Typography.Text>
                  {form.getFieldDecorator('customEmailText', {
                    initialValue: data?.getOrganization?.customEmailText,
                  })(<Input.TextArea rows={5} style={{ fontFamily: 'monospace' }} />)}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export const EmailSettingsCard = Form.create<Props>({ name: 'Email Settings' })(EmailSettingsCardComponent);
